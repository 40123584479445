import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PageHeader from "../components/PageHeader/pageHeader";
import TwoColumnsSection from "../components/TwoColumnsSection/twoColumnsSection";
import FeaturesList from "../components/FeaturesList/featuresList";
import PhotoWithMessages from "../components/forClinics/PhotoWithMessages/photoWithMessages";
import Messaging from "../components/forClinics/Messaging/messaging";
import SecureCalling from "../components/forClinics/SecureCalling/secureCalling";
import SeamlessPayments from "../components/forClinics/SeamlessPayments/seamlessPayments";
import HarnessData from "../components/forClinics/HarnessData/harnessData";
import PatientRetention from "../components/forClinics/PatientRetention/patientRetention";
import { useTranslation } from "react-i18next";
import * as styles from "../components/forClinics/styles.module.scss";

const ForClinicsPage = () => {
  const { t } = useTranslation();
  const clinicsPageData = [
    {
      title: t("clinic.automate.title"),
      desc: t("clinic.automate.copy"),
      features: [
        t("clinic.automate.bullet.1"),
        t("clinic.automate.bullet.2"),
        t("clinic.automate.bullet.3"),
        t("clinic.automate.bullet.4"),
      ],
    },
    {
      title: t("clinic.messaging.title"),
      desc: t("clinic.messaging.copy"),
      features: [
        t("clinic.messaging.bullet.1"),
        t("clinic.messaging.bullet.2"),
        t("clinic.messaging.bullet.3"),
        t("clinic.messaging.bullet.4"),
      ],
    },
    {
      title: t("clinic.secure.title"),
      desc: t("clinic.secure.copy"),
      features: [
        t("clinic.secure.bullet.1"),
        t("clinic.secure.bullet.2"),
        t("clinic.secure.bullet.3"),
      ],
    },
    {
      title: t("clinic.payments.title"),
      desc: t("clinic.payments.copy"),
      features: [t("clinic.payments.bullet.1")],
    },
    {
      title: t("clinic.retention.title"),
      desc: t("clinic.retention.copy"),
      features: [
        t("clinic.retention.bullet.1"),
        t("clinic.retention.bullet.2"),
      ],
    },
    {
      title: t("clinic.data.title"),
      desc: t("clinic.data.copy"),
      features: [
        t("clinic.data.bullet.1"),
        t("clinic.data.bullet.2"),
        t("clinic.data.bullet.3"),
        t("clinic.data.bullet.4"),
      ],
    },
  ];
  return (
    <Layout menuColor={"black"}>
      <SEO title={t("menu.forClinics")} />
      <PageHeader title={t("clinic.header")} desc={t("clinic.subheader")} />

      <TwoColumnsSection>
        <PhotoWithMessages />
        <FeaturesList
          className={styles.firstFeatureList}
          data={clinicsPageData[0]}
        />
      </TwoColumnsSection>

      <TwoColumnsSection
        className={styles.secondFeatureColumn}
        reverseOrderOnMobile
      >
        <FeaturesList
          className={styles.secondFeatureList}
          data={clinicsPageData[1]}
        />
        <Messaging />
      </TwoColumnsSection>

      <TwoColumnsSection>
        <SecureCalling />
        <FeaturesList
          className={styles.thirdFeatureList}
          data={clinicsPageData[2]}
        />
      </TwoColumnsSection>

      <TwoColumnsSection reverseOrderOnMobile>
        <FeaturesList
          className={styles.fourthFeatureList}
          data={clinicsPageData[3]}
        />
        <SeamlessPayments />
      </TwoColumnsSection>

      <TwoColumnsSection>
        <PatientRetention />
        <FeaturesList
          className={styles.fifthFeatureList}
          data={clinicsPageData[4]}
        />
      </TwoColumnsSection>

      <TwoColumnsSection reverseOrderOnMobile>
        <FeaturesList data={clinicsPageData[5]} />
        <HarnessData />
      </TwoColumnsSection>
    </Layout>
  );
};

export default ForClinicsPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
