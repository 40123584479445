import React from "react";
import * as styles from "./patientRetention.module.scss";
import roundedArrow from "./rounded-arrow.svg";
import ScrollAnimation from "react-animate-on-scroll";

const PatientRetention = () => {
  return (
    <ScrollAnimation animateIn="fadeInLeft" animateOnce>
      <div className={styles.patientRetentionWrapper}>
        <img src={roundedArrow} className={styles.arrow} alt="" />
        <div className={styles.notification}>
          <div>
            <div className={styles.notificationTitle}>Salve</div>
            <div className={styles.notificationText}>
              Hi Jane, it’s been 3 months since your last visit, so we are
              reaching out to arrange your follow up consultation.
            </div>
          </div>
        </div>
        <div className={styles.greenDeco} />
      </div>
    </ScrollAnimation>
  );
};

export default PatientRetention;
