import React from "react";
import * as styles from "./photoWithMessages.module.scss";
import ladyPhoto from "./for-clinic.png";
import ScrollAnimation from "react-animate-on-scroll";
import { Trans } from "react-i18next";

const PhotoWithMessages = () => {
  return (
    <div className={styles.photoWithMessages}>
      <div className={styles.purpleDecoration} />
      <div className={styles.photoWrapper}>
        <img src={ladyPhoto} alt="" className={styles.ladyPhoto} />
        <div className={styles.notifications}>
          <ScrollAnimation animateIn="fadeInLeft" delay={0} animateOnce>
            <div className={`${styles.notification} ${styles.first}`}>
              <div>
                <div className={styles.notificationTitle}>Salve</div>
                <div className={styles.notificationText}>
                  <Trans>clinic.automate.message.1</Trans>
                </div>
              </div>
            </div>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeInLeft" delay={200} animateOnce>
            <div className={`${styles.notification} ${styles.second}`}>
              <div>
                <div className={styles.notificationTitle}>Salve</div>
                <div className={styles.notificationText}>
                  <Trans>clinic.automate.message.2</Trans>
                </div>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInLeft" delay={400} animateOnce>
            <div className={`${styles.notification} ${styles.third}`}>
              <div>
                <div className={styles.notificationTitle}>Salve</div>
                <div className={styles.notificationText}>
                  <Trans>clinic.automate.message.3</Trans>
                </div>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInLeft" delay={600} animateOnce>
            <div className={`${styles.notification} ${styles.fourth}`}>
              <div>
                <div className={styles.notificationTitle}>Salve</div>
                <div className={styles.notificationText}>
                  <Trans>clinic.automate.message.4</Trans>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    </div>
  );
};

export default PhotoWithMessages;
