import React from "react";
import * as styles from "./messaging.module.scss";
import messaging from "./messaging.png";
import chat from "./chat.png";
import ScrollAnimation from "react-animate-on-scroll";

const Messaging = () => {
  return (
    <div style={{ position: "relative" }}>
      <ScrollAnimation animateIn="fadeIn" animateOnce>
        <div>
          <div className={styles.decorationGreen} />
        </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInRight" animateOnce>
        <div className={styles.messagingWrapper}>
          <img src={messaging} className={styles.photoMessages} alt="" />
          <img src={chat} alt="" className={styles.photoPhone} />
          <div className={styles.decorationRed} />
        </div>
      </ScrollAnimation>
    </div>
  );
};

export default Messaging;
