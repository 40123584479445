import React from "react";
import * as styles from "./harnessData.module.scss";
import analytics from "./analytics.jpg";
import ScrollAnimation from "react-animate-on-scroll";

const HarnessData = () => {
  return (
    <div className={styles.dataWrapper}>
      <ScrollAnimation
        animateIn="fadeInRight"
        animateOnce
        style={{ zIndex: 20, position: "relative" }}
      >
        <img src={analytics} className={styles.analytics} alt="" />
      </ScrollAnimation>
      <div className={styles.decorationPurple} />
    </div>
  );
};

export default HarnessData;
