import React from "react";
import * as styles from "./secureCalling.module.scss";
import video from "./video.jpg";
import videoMobile from "./video-mobile.jpg";
import ScrollAnimation from "react-animate-on-scroll";

const SecureCalling = () => {
  return (
    <div className={styles.secureCallingWrapper}>
      <ScrollAnimation animateIn="fadeInleft" animateOnce>
        <img src={video} className={styles.videoPhoto} alt="" />
        <img src={videoMobile} className={styles.videoPhotoMobile} alt="" />
      </ScrollAnimation>
    </div>
  );
};

export default SecureCalling;
