// extracted by mini-css-extract-plugin
export var photoWithMessages = "photoWithMessages-module--photoWithMessages--3zb8L";
export var photoWrapper = "photoWithMessages-module--photoWrapper--2KUVr";
export var ladyPhoto = "photoWithMessages-module--ladyPhoto--2LDbI";
export var notification = "photoWithMessages-module--notification--2VP-o";
export var notificationTitle = "photoWithMessages-module--notificationTitle--onwEf";
export var notificationText = "photoWithMessages-module--notificationText--sE3E-";
export var purpleDecoration = "photoWithMessages-module--purpleDecoration--38fg3";
export var notifications = "photoWithMessages-module--notifications--ZHWB0";
export var first = "photoWithMessages-module--first--22ntw";
export var second = "photoWithMessages-module--second--2bqLg";
export var third = "photoWithMessages-module--third--1T5Bk";
export var fourth = "photoWithMessages-module--fourth--g1BNp";
export var fifth = "photoWithMessages-module--fifth--R6QtI";