import React from "react";
import * as styles from "./seamlessPayments.module.scss";
import couple from "./couple.png";
import ScrollAnimation from "react-animate-on-scroll";
import motion from "./motionSmall.mp4";

const SeamlessPayments = () => {
  return (
    <ScrollAnimation animateIn="fadeInRight" animateOnce>
      <div className={styles.paymentWrapper}>
        <img src={couple} className={styles.couplePhoto} alt="" />
        <div className={styles.phonePhoto}>
          <video autoPlay loop muted playsInline>
            <source src={motion} type="video/mp4" />
          </video>
        </div>
        <div className={styles.decoGreen} />
      </div>
    </ScrollAnimation>
  );
};

export default SeamlessPayments;
